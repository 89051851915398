import React from 'react'

import Alert from 'react-bootstrap/Alert'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'

import properties from '../properties'

import Input from './input'
import Textarea from './textarea'
import Check from './check'
import Radio from './radio'
import Select from './select'
import Label from './label'
import Rank from './rank'
import FileUpload from './fileupload'
import Typeahead from './typeahead'
import Fee from './fee'
//import Conditional from './conditional'

import _ from 'underscore'

/** 
 * Used for form validation
 * Formik: https://jaredpalmer.com/formik/docs/overview
 * Yup: https://www.npmjs.com/package/yup
 */
import { Formik } from 'formik'
import * as Yup from 'yup'

/**/

class QualtricsForm extends React.Component {
    constructor(props) {
        super(props)
        
        this.state = {
            successShow: false,
            failShow: false,
            errorShow: false,
            successMessage: '',
            validationErrors: {},
            isSubmitting: false,
            hasFees: false,
            hasErrors: false
        }

        this.fieldType = this.fieldType.bind(this)
        this.postFormData = this.postFormData.bind(this)

        this.generateValidationSchema()
    }

    /**
     * Generate a Yup validation schema & intial values for Formik BEFORE component render
     */
    generateValidationSchema() {
        this.validationSchema = {}
        this.initialValues = {}
        
        this.props.fields.forEach(field => {
            this.initialValues[field.id] = field.question_values || ''
            
            // Use state to manage file data
            if (field.question_type === "fileupload") {
                this.state[field.id] = ''
            }

            // Use state to manage rank ordering
            if (field.question_type === "rank") {
                this.state[field.id] = field.question_values 
            }

            // Use a boolean when checkbox field doesn't contain any values (empty string)
            if (field.question_type === "checkbox" && field.question_values === "") {
                this.initialValues[field.id] = false 
            }

            if (field.question_type === "checkbox" && field.question_values.split('\n').length > 1) {
                this.initialValues[field.id] = []
            }

            // Fee fields in current state don't have an input, but need to pass the formvalue back to the server
            if (field.question_type === "fee") {
                this.initialValues[field.id] = field.fee_values.fees[0].formvalue
                this.state.hasFees = true
            }

            // Add field to validation schema when it's required
            if (field.is_required) {
                switch (field.question_type) {
                    default:
                    case "textinput":
                    case "firstname":
                    case "lastname":
                    case "textarea-small":
                    case "textarea-large":
                    case "radio":
                    case "typeahead":
                        return this.validationSchema[field.id] = Yup.string().required(properties.requiredField)
                    
                    case "email":
                    case "bcc":
                        return this.validationSchema[field.id] = Yup.string().email(properties.invalidEmail).required(properties.requiredField)

                    case "date":
                        return this.validationSchema[field.id] = Yup.date().required(properties.requiredField)
                    
                    case "fileupload":
                        return this.validationSchema[field.id] = Yup.mixed().required(properties.requiredField)

                    case "guest_input":
                        return this.validationSchema[field.id] = Yup.string().required(properties.requiredField)

                    case "dropdown":
                    case "guest_dropdown":
                        return this.validationSchema[field.id] = Yup.string().required(properties.requiredField)

                    case "checkbox":
                    case "guest_checkbox":
                        let values = field.question_values.split('\n')

                        // validation for a single checkbox with no question value
                        if (values.length === 1 && values[0] === "") {
                            return this.validationSchema[field.id] = Yup.bool().oneOf([true], properties.requiredField)    
                        }

                        // validation for a single checkbox with a question value
                        if (values.length <= 1) {
                            return this.validationSchema[field.id] = Yup.string().required(properties.requiredField)
                        }

                        // validation for multiple checkboxes
                        if (values.length > 1) {
                            return this.validationSchema[field.id] = Yup.array().required(properties.checkboxesRequired)    
                        }

                        return

                    case "fee":
                        return this.validationSchema[field.id] = Yup.string().required(properties.requiredField)
                }
            }
        })
    }

    /**
     * Takes a field and returns a component based on question type
     *
     * @param {Object} field
     * @returns Component
     * @memberof EventForm
     */
    fieldType(field, setFieldValue) { 
        switch (field.question_type) {
            default:
            case "label":
                return <Label/>
            
            case "firstname":
            case "lastname":
                return <Input type="text"/>
            
            case "email":
                return <Input type="email"/>

            case "date":
                return <Input type="date"/>

            case "fileupload":
                return <FileUpload
                            type="file"
                            onChange={event => {
                                let file = event.currentTarget.files[0]
                                
                                this.setState({
                                    [field.id]: file
                                })

                                setFieldValue(field.id, file)
                            }}
                        />

            case "guest_input":
                return <Input type="number" min={0} max={100} className="w-25"/>

            case "textarea-small":
                return <Textarea rows={3}/>

            case "textarea-large":
                return <Textarea rows={8}/>

            case "radio":
                return <Radio/>

            case "checkbox":
            case "guest_checkbox":
                return <Check/>

            case "dropdown":
            case "guest_dropdown":
                return <Select/>

            case "rank":
                return <Rank onChange={items => {
                    this.setState({
                        [field.id]: items
                    })
                }}/>

            case "typeahead":
                return <Typeahead/>

            case "fee":
                return <Fee/>
                
        }
    }

    async postFormData(values, setSubmitting) {
        let formData = new FormData()
        let fields = this.props.fields
        let QID = this.props.data.QID

        this.setState({ isSubmitting: true })

        let url = await fetch('/url', { method: 'POST'}).then(res => res.text())
        
        formData.append('qid', QID)

        _.each(values, (value, field) => {
            let questionType = _.findWhere(fields, { id: field }).question_type
            
            if (questionType === "fileupload") {
                formData.append(`field[${field}]`, this.state[field])
                return
            }

            if (questionType === "rank") {
                formData.append(`field[${field}]`, this.state[field])
                return
            }

            if (questionType === "fee") {
                formData.append(`field[${field}][]`, value)
                formData.append(`fee_quantity[${value}][qty]`, 1)
                return
            }

            if (questionType === "typeahead") {
                formData.append(`field[${field}]`, value)
                return
            }

            formData.append(`field[${field}]`, value)
        })

        fetch(`${url}${this.props.url}`, {
            method: 'POST',
            body: formData
        }).then(results => {
            // catch all server errors
            if (results.status !== 200) {
                this.setState({
                    errorShow: true
                })
                return
            }
            
            return results.json()
        }).then(json => {
            
            // form submit was successful if there are no validation errors (status code 0)
            if (json.status_code === 0) {

                // show success message, upload is complete
                this.setState({
                    successShow: true,
                    successMessage: json.registration_process_complete_message
                })
            }

            // form submit was not successful if there are any errors (any status code other than 0)
            if (json.status_code !== 0) {
                this.setState({
                    failShow: true,
                    validationErrors: json.errors
                })
            }

            console.log(json)
        }).catch(error => {
            this.setState({
                errorShow: true
            })
            console.log(properties.error, error)
        }).finally(() => {
            this.setState({ isSubmitting: false }) 
            setSubmitting(false)   
        })
    }

    render() {
        return (
            <>
                <Formik
                    validationSchema={Yup.object().shape(this.validationSchema)}
                    initialValues={this.initialValues}
                    onSubmit={(values, { setSubmitting, isValid }) => {
                        this.postFormData(values, setSubmitting)
                    }}
                >
                    {({
                        handleSubmit,
                        handleChange,
                        handleBlur,
                        values,
                        touched,
                        errors,
                        isSubmitting,
                        setFieldValue,
                        setFieldTouched,
                        validateForm
                    }) => (
                        <Form onSubmit={handleSubmit}>

                            {this.state.hasErrors && <Alert variant="danger">{properties.invalidForm}</Alert>}

                            {this.props.fields.map((field, key) => { 
                                const component = this.fieldType(field, setFieldValue)
                                const onChange = component.props.onChange
                                const onBlur = component.props.onBlur

                                return React.cloneElement(component, {
                                    field: field,
                                    key: key,
                                    errors: errors,
                                    touched: touched,
                                    values: values,
                                    onChange: onChange || handleChange,
                                    onBlur: onBlur || handleBlur,
                                    setFieldValue: setFieldValue,
                                    setFieldTouched: setFieldTouched
                                })
                            })}
                            
                            <button
                                type="submit"
                                disabled={isSubmitting}
                                onClick={() => {
                                    validateForm().then((errors) => {
                                        let hasErrors = !_.isEmpty(errors)
                                        let viewportOffset = document.getElementById('form-wrapper').getBoundingClientRect()
                                        
                                        this.setState({ hasErrors: hasErrors }, () => {
                                            if (hasErrors) {
                                                window.scrollTo({ 
                                                    top: viewportOffset.top + window.scrollY,
                                                    left: 0,
                                                    behavior: 'smooth'
                                                })
                                            }
                                        })
                                    })
                                }}
                            >
                                {properties.submit}
                                <Spinner
                                    as="span"
                                    animation="border"
                                    size="sm"
                                    role="status"
                                    aria-hidden="true"
                                    className="ml-2"
                                    style={{display: this.state.isSubmitting ? 'inline-block' : 'none' }}
                                />
                            </button>
                        </Form>
                    )}
                </Formik>
                <SuccessModal 
                    show={this.state.successShow}
                    onHide={() => { 
                        this.setState({ successShow: false })

                        if (this.props.data.options.return_url) {
                            window.location.assign(this.props.data.options.return_url)
                        }
                    }}
                    message={this.state.successMessage}
                />
                <FailModal
                    show={this.state.failShow}
                    onHide={() => this.setState({ failShow: false })}
                    validationErrors={this.state.validationErrors}
                />
                <ErrorModal
                    show={this.state.errorShow}
                    onHide={() => this.setState({ errorShow: false })}
                />
            </>
        )
    }
}

const ErrorModal = props => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.qualtrics.uploadFail}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {properties.qualtrics.uploadError}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide}>{properties.closeButton}</button>
            </Modal.Footer>
        </Modal>
    )
}

const SuccessModal = props => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.qualtrics.uploadComplete}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: props.message }}/>
            <Modal.Footer>
                <button onClick={props.onHide}>{properties.closeButton}</button>
            </Modal.Footer>
        </Modal>
    )
}

const FailModal = props => {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.qualtrics.uploadFail}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup variant="flush">
                    {
                        _.map(props.validationErrors, (error, key) => {
                            return (
                                <ListGroup.Item key={key}>
                                    <span className="text-danger">{error}</span>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide}>{properties.closeButton}</button>
            </Modal.Footer>
        </Modal>
    )    
}

export default QualtricsForm