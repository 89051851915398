import React from 'react'

import Alert from 'react-bootstrap/Alert'
import ButtonToolbar from 'react-bootstrap/ButtonToolbar'
import Form from 'react-bootstrap/Form'
import Modal from 'react-bootstrap/Modal'
import ListGroup from 'react-bootstrap/ListGroup'
import Spinner from 'react-bootstrap/Spinner'

import properties from '../properties'

import Input from './input'
import Textarea from './textarea'
import Check from './check'
import Radio from './radio'
import Select from './select'
import Label from './label'
import Rank from './rank'
import FileUpload from './fileupload'
import Typeahead from './typeahead'
import Fee from './fee'
import Gift from './gift'
//import Conditional from './conditional'

import _ from 'underscore'
import TagManager from 'react-gtm-module'
import ReCAPTCHA from 'react-google-recaptcha'

/** 
 * Used for form validation
 * Formik: https://jaredpalmer.com/formik/docs/overview
 * Yup: https://www.npmjs.com/package/yup
 */
import { Formik } from 'formik'
import * as Yup from 'yup'

// TODO: Temp hack for SF-915, to be removed
//import runtimeEnv from '@mars/heroku-js-runtime-env'
//const env = runtimeEnv()
//const production = env && env.REACT_APP_ENV === "production"
/**/

/**
 * Renders the event form using custom field components
 *
 * @class EventForm
 * @extends {React.Component}
 */
class EventForm extends React.Component {
    constructor(props) {
        super(props)

        this.state = {
            successShow: false,
            failShow: false,
            errorShow: false,
            termsAndConditionsShow: false,
            payLaterConfirmationShow: false,
            paypalShow: false,
            registrationComplete: '',
            successMessage: '',
            validationErrors: {},
            isSubmitting: false,
            hasFees: false,
            hasErrors: false,
            processor: {},
            formComplete: false,
            values: {},
            setSubmitting: null,
            initialValues: null,
            validationSchema: null,
            showCAPTCHA: false,
            validCAPTCHA: false
        }

        this.fieldType = this.fieldType.bind(this)
        this.postFormData = this.postFormData.bind(this)
        this.generateValidationSchema = this.generateValidationSchema.bind(this)

        // enable Google Analytics when an ID is configured in data.options
        // Used by Exec Ed events
        // See https://jira.som.yale.edu/browse/DESK-110226
        let GTM = this.props.data.options.analytic_id

        if (GTM) {
            console.log('Init GTM using ID:', GTM)

            TagManager.initialize({
                gtmId: GTM
            })
        }

        // enable use of Google CAPTCHA when configured in data.options
        // requires a site key for reCAPTCHA using a Google account
        // See https://jira.som.yale.edu/browse/DESK-144558
        let useCAPTCHA = this.props.data.options.use_captcha

        if (useCAPTCHA === true) {
            this.state.showCAPTCHA = true
        }
    }

    componentDidMount() {
        const [initialValues, validationSchema] = this.generateValidationSchema()
        this.setState({ 
            initialValues,
            validationSchema: Yup.object().shape(validationSchema)
        })
    }

    /**
     * Generate a Yup validation schema & initial values for Formik BEFORE component render
     *
     * @memberof EventForm
     */
    generateValidationSchema() {
        let validationSchema = {}
        let initialValues = {}
        
        this.props.fields.forEach(field => {
            initialValues[field.id] = ''

            if (field.question_type === "textinput" ||
                field.question_type === "firstname" ||
                field.question_type === "lastname" ||
                field.question_type === "email") {
                    initialValues[field.id] = field.question_values

            }
            
            // ===========================================
            // TODO: Directly manipulating state = bad
            // Use state to manage file data
            if (field.question_type === "fileupload") {
                this.state[field.id] = ''
            }

            // Use state to manage rank ordering
            if (field.question_type === "rank") {
                this.state[field.id] = field.question_values 
            }

            // Use a boolean when checkbox field doesn't contain any values (empty string)
            if (field.question_type === "checkbox" && field.question_values === "") {
                initialValues[field.id] = false 
            }

            if (field.question_type === "checkbox" && field.question_values.split('\n').length > 1) {
                initialValues[field.id] = []
            }

            // Fee fields with question sub type "single_line" in current state don't have an input, but need to pass the form value back to the server
            if (field.question_type === "fee" && field.question_sub_type === "single_line") {
                initialValues[field.id] = field.fee_values.fees[0].formvalue
                this.state.hasFees = true
            }
            // ===========================================

            // Add field to validation schema when it's required
            if (field.is_required) {
                switch (field.question_type) {
                    default:
                    case "textinput":
                    case "firstname":
                    case "lastname":
                    case "textarea-small":
                    case "textarea-large":
                    case "radio":
                    case "typeahead":
                    case "classgift_amount_radio":
                        return validationSchema[field.id] = Yup.string().required(properties.requiredField)

                    case "numeric":
                        if (field.options && field.options.field) {
                            return validationSchema[field.id] = Yup.number().min(field.options.field.min, properties.requiredMin).max(field.options.field.max, properties.requiredMax).required(properties.requiredField)
                        }

                        return validationSchema[field.id] = Yup.number().required(properties.requiredField)
                    case "email":
                        // TODO: Special validation for the Email field using Class Gift template
                        // The email field for Class Gift requires the email entered to be a non-yale email address
                        // Find a more elegant way to do this in the future with templating
                        let template = this.props.data.options.template
                        
                        if (template === "classgift") {
                            return validationSchema[field.id] = Yup.string().email(properties.invalidEmail).required(properties.requiredField).test('yale-domain', properties.nonYaleEmail, value => {
                                return value && !value.includes('@yale.edu')
                            })
                        }
                        // default email validation
                        return validationSchema[field.id] = Yup.string().email(properties.invalidEmail).required(properties.requiredField)
                    case "bcc":
                        return validationSchema[field.id] = Yup.string().email(properties.invalidEmail).required(properties.requiredField)

                    case "date":
                        return validationSchema[field.id] = Yup.date().required(properties.requiredField)
                    
                    case "fileupload":
                        return validationSchema[field.id] = Yup.mixed().required(properties.requiredField)

                    case "guest_input":
                        return validationSchema[field.id] = Yup.string().required(properties.requiredField)

                    case "dropdown":
                    case "guest_dropdown":
                        return validationSchema[field.id] = Yup.string().required(properties.requiredField)

                    case "checkbox":
                    case "guest_checkbox":
                        let values = field.question_values.split('\n')

                        // validation for a single checkbox with no question value
                        if (values.length === 1 && values[0] === "") {
                            return validationSchema[field.id] = Yup.bool().oneOf([true], properties.requiredField)    
                        }

                        // validation for a single checkbox with a question value
                        if (values.length <= 1) {
                            return validationSchema[field.id] = Yup.string().required(properties.requiredField)
                        }

                        // validation for multiple checkboxes
                        if (values.length > 1) {
                            return validationSchema[field.id] = Yup.array().required(properties.checkboxesRequired)    
                        }

                        return

                    case "fee":
                        return validationSchema[field.id] = Yup.string().required(properties.requiredField)

                    case "conditional":
                        return validationSchema[field.id] = Yup.string().test('conditional-test', 'error message', function(value) {
                            
                            console.log('here')
                            console.log(value)
                            return true
                        })
                }
            }
        })
        
        return [initialValues, validationSchema]
    }

    /**
     * Takes a field and returns a component based on question type
     *
     * @param {Object} field
     * @returns Component
     * @memberof EventForm
     */
    fieldType(field, setFieldValue) { 
        switch (field.question_type) {
            default:
            case "label":
                return <Label/>
            
            case "textinput":
            case "firstname":
            case "lastname":
                return <Input type="text"/>

            case "numeric":
                return <Input type="number" min={field.options?.field?.min} max={field.options?.field?.max}/>

            case "discount":
                return <Input type="text" className="w-25"/>
            
            case "email":
            case "bcc":
                return <Input type="email"/>

            case "date":
                return <Input type="date"/>

            case "fileupload":
                return <FileUpload
                            type="file"
                            onChange={event => {
                                let file = event.currentTarget.files[0]
                                
                                this.setState({
                                    [field.id]: file
                                })

                                setFieldValue(field.id, file)
                            }}
                        />

            case "guest_input":
                return <Input type="number" min={0} max={100} className="w-25"/>

            case "textarea-small":
                return <Textarea rows={3}/>

            case "textarea-large":
                return <Textarea rows={8}/>

            case "radio":
                return <Radio/>

            case "checkbox":
            case "guest_checkbox":
                return <Check/>

            case "dropdown":
            case "guest_dropdown":
                return <Select/>

            case "rank":
                return <Rank onChange={items => {
                    this.setState({
                        [field.id]: items
                    })
                }}/>

            case "typeahead":
                return <Typeahead/>

            case "fee":
                return <Fee/>

            case "classgift_amount_radio":
                let displayInstallments = this.props.data.options.template_display_attributes.classgift_installment_schedule
                return <Gift displayInstallments={displayInstallments}/>
            
            case "reuniongift_amount_radio":
                return <Gift/>

            /*case "conditional":
                return <Conditional/>*/
                
        }
    }

    async postFormData(values, setSubmitting) {
        let formData = new FormData()
        let fields = this.props.fields
        let id = this.props.id
        
        this.setState({ isSubmitting: true })

        let url = await fetch('/url', { method: 'POST'}).then(res => res.text())

        // append the NetID to Form Data for CAS authenticated forms
        if (id) {
            formData.append(`id`, id)
        }
        
        _.each(values, (value, field) => {
            const fieldRef = _.findWhere(fields, { id: field })
            const questionType = fieldRef.question_type
            
            if (questionType === "fileupload") {
                formData.append(`field[${field}]`, this.state[field])
                return
            }

            if (questionType === "rank") {
                formData.append(`field[${field}]`, this.state[field])
                return
            }

            if (questionType === "fee") {
                formData.append(`field[${field}][]`, value)
                
                // append a fee_quantity field for each fee
                _.each(fieldRef.fee_values.fees, fee => {
                    formData.append(`fee_quantity[${fee.formvalue}][qty]`, 1)
                })

                return
            }

            if (questionType === "typeahead") {
                formData.append(`field[${field}]`, value)
                return
            }

            if (questionType === "classgift_amount_radio") {
                value = JSON.parse(value)
                formData.append(`field[${field}]`, value.total)
                formData.append(`installment[1]`, value.installment)
                formData.append(`installment[2]`, value.installment)
                formData.append(`installment[3]`, value.installment)
                formData.append(`installment[4]`, value.installment)
                return
            }

            formData.append(`field[${field}]`, value)
        })

        fetch(`${url}${this.props.url}`, {
            method: 'POST',
            body: formData
        }).then(results => {
            // catch all server errors
            if (results.status !== 200) {
                this.setState({
                    errorShow: true
                })
                return
            }
            
            return results.json()
        }).then(json => {
            let template = this.props.data.options.template

            // form submit was successful if there are no validation errors (status code 0)
            if (json.status_code === 0) {
                
                // TODO: Find more elegant solution for templating/form submit workflow
                if (template === "classgift") {
                    this.setState({
                        formComplete: true,
                        registrationComplete: json.registration_process_complete_message
                    })
                    return
                }

                // show success message, registration is complete
                this.setState({
                    successShow: true,
                    successMessage: json.parse_msg,
                    registrationComplete: json.registration_process_complete_message
                })
            }

            // form submit was successful, pending enrollment
            if (json.status_code === 3) {
                
                // show paypal modal
                this.setState({
                    paypalShow: true,
                    processor: json.processor,
                    registrationComplete: json.registration_process_complete_message
                }) 
            }

            // form submit was not successful if there are validation errors (status code -1)
            if (json.status_code === -1) {
                this.setState({
                    failShow: true,
                    validationErrors: json.validation_errors
                })
            }
            console.log(json)
        }).catch(error => {
            this.setState({
                errorShow: true
            })
            console.log(properties.error, error)
        }).finally(() => {
            this.setState({ isSubmitting: false }) 
            setSubmitting(false)   
        })
    }

    render() {
        let formComplete = this.state.formComplete

        // Ensure the form has been initialized before rendering
        if (!this.state.initialValues || !this.state.validationSchema) {
            return <></>
        }
        
        return (
            <>
                {!formComplete && 
                    <Formik
                        validationSchema={this.state.validationSchema}
                        initialValues={this.state.initialValues}
                        onSubmit={(values, { setSubmitting, isValid }) => {
                            let data = this.props.data
                            let hasTermsAndConditions = !!data.terms_and_conditions

                            // check to see if the form contains terms and conditions copy, if it does we will
                            // display that modal before posting form data, otherwise just submit the form
                            if (hasTermsAndConditions) {
                                this.setState({
                                    termsAndConditionsShow: true,
                                    values: values,
                                    setSubmitting: setSubmitting
                                })

                                return
                            }

                            // submit form
                            this.postFormData(values, setSubmitting)
                        }}
                    >
                        {({
                            handleSubmit,
                            handleChange,
                            handleBlur,
                            values,
                            touched,
                            errors,
                            isSubmitting,
                            setFieldValue,
                            setFieldTouched,
                            validateForm
                        }) => (
                            <Form onSubmit={handleSubmit} noValidate>

                                {this.state.hasErrors && <Alert variant="danger">{properties.invalidForm}</Alert>}

                                {this.props.fields.map((field, key) => { 
                                    const component = this.fieldType(field, setFieldValue)
                                    const onChange = component.props.onChange
                                    const onBlur = component.props.onBlur

                                    return React.cloneElement(component, {
                                        field,
                                        fields: this.props.fields,
                                        key,
                                        errors,
                                        touched,
                                        values,
                                        onChange: onChange || handleChange,
                                        onBlur: onBlur || handleBlur,
                                        setFieldValue: setFieldValue,
                                        setFieldTouched: setFieldTouched,
                                        validationSchema: this.state.validationSchema,
                                        setValidationSchema: (schema) => this.setState({ validationSchema: schema }),
                                    })
                                })}

                                {this.state.showCAPTCHA &&
                                    <div className="pt-3 pb-3">
                                        <ReCAPTCHA
                                            sitekey="6Ld9ECwqAAAAAF_SItPLMLdcnDg18cxPvFzvPV1Z"
                                            onChange={() => this.setState({ validCAPTCHA: true })}
                                        />
                                    </div>
                                }
                                
                                <button
                                    type="submit"
                                    disabled={isSubmitting || (this.state.showCAPTCHA && !this.state.validCAPTCHA)}
                                    onClick={() => {
                                        validateForm().then((errors) => {
                                            let hasErrors = !_.isEmpty(errors)
                                            let viewportOffset = document.getElementById('form-wrapper').getBoundingClientRect()
                                            
                                            this.setState({ hasErrors: hasErrors }, () => {
                                                if (hasErrors) {
                                                    window.scrollTo({ 
                                                        top: viewportOffset.top + window.scrollY,
                                                        left: 0,
                                                        behavior: 'smooth'
                                                    })
                                                }
                                            })
                                        })
                                    }}
                                >
                                    {properties.submit}
                                    <Spinner
                                        as="span"
                                        animation="border"
                                        size="sm"
                                        role="status"
                                        aria-hidden="true"
                                        className="ml-2"
                                        style={{display: this.state.isSubmitting ? 'inline-block' : 'none' }}
                                    />
                                </button>
                            </Form>
                        )}
                    </Formik>
                }

                {formComplete &&
                    <Alert variant='success' className="text-center">
                        {this.state.registrationComplete}
                    </Alert>
                }
                <SuccessModal 
                    show={this.state.successShow}
                    onHide={() => {
                        let template = this.props.data.options.template

                        this.setState({ successShow: false })

                        // TODO: Hack, Reload page to reset Staff Award Nomination form
                        if (template === "staffawards") {
                            window.location.reload()
                        }
                        
                    }}
                    message={this.state.successMessage || this.state.registrationComplete}
                    template={this.props.data.options.template}
                />
                <FailModal
                    show={this.state.failShow}
                    onHide={() => this.setState({ failShow: false })}
                    validationErrors={this.state.validationErrors}
                />
                <ErrorModal
                    show={this.state.errorShow}
                    onHide={() => this.setState({ errorShow: false })}
                />
                <PaymentModal
                    data={this.props.data}
                    show={this.state.paypalShow}
                    processor={this.state.processor}
                    onHide={() => { 
                        this.setState({
                            paypalShow: false,
                            payLaterConfirmationShow: true
                        })
                    }}
                    paymentDetails={this.props.data.payment_details_raw}
                />
                <TermsAndConditionsModal
                    show={this.state.termsAndConditionsShow}
                    onHide={() => {
                        this.setState({ termsAndConditionsShow: false })
                        let setSubmitting = this.state.setSubmitting

                        setSubmitting(false)
                    }}
                    onAccept={() => {
                        let values = this.state.values
                        let setSubmitting = this.state.setSubmitting

                        this.setState({
                            termsAndConditionsShow: false
                        }, () => {
                            this.postFormData(values, setSubmitting)
                        }) 
                    }}
                    terms={this.props.data.terms_and_conditions}
                />
                <PayLaterConfirmationModal
                    show={this.state.payLaterConfirmationShow}
                    registrationComplete={this.state.registrationComplete}
                />
            </>
        )
    }
}

const ErrorModal = props => {
    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.registrationFail}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                {properties.registrationError}
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide}>{properties.closeButton}</button>
            </Modal.Footer>
        </Modal>
    )
}

const SuccessModal = props => {
    let template = props.template

    return (
        <Modal
            {...props}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {/* TODO: Hack for Reunion Gift Campaign */}
                    {template === 'reuniongift' ? properties.reunionGiftCampaign : properties.registrationComplete}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: props.message }}/>
            <Modal.Footer>
                <button onClick={() => window.location="https://som.yale.edu/"}>{properties.closeButton}</button>
            </Modal.Footer>
        </Modal>
    )
}

const FailModal = props => {
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.registrationFail}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <ListGroup variant="flush">
                    {
                        _.map(props.validationErrors, (error, key) => {
                            return (
                                <ListGroup.Item key={key}>
                                    <strong>{error.display_name}</strong>
                                    <br/>
                                    <span className="text-danger">{error.msg}</span>
                                </ListGroup.Item>
                            )
                        })
                    }
                </ListGroup>
            </Modal.Body>
            <Modal.Footer>
                <button onClick={props.onHide}>{properties.closeButton}</button>
            </Modal.Footer>
        </Modal>
    )    
}

const PaymentModal = props => {
    let processor = props.processor
    let paymentDetails = props.paymentDetails || '%payment_fee%'

    // credit_card, invoice, both_cc_and_invoice, none, null
    let paymentType = props.data.options.salesforce_payment_option_type
    
    const displayPayNowButton = function() {
        switch (paymentType) {
            default:
            case null:
            case 'credit_card':
            case 'both_cc_and_invoice':
                // upay is the default processor
                if (processor.PAY_TYPE && processor.PAY_TYPE === 'upay') {
                    return <form id="upay" method="POST" action={processor.URL}>
                        <input type="hidden" name="UPAY_SITE_ID" value={processor.UPAY_SITE_ID}/>
                        <input type="hidden" name="AMT" value={processor.AMT}/>
                        <input type="hidden" name="BILL_EMAIL_ADDRESS" value={processor.BILL_EMAIL_ADDRESS}/>
                        <input type="hidden" name="COMMENT2" value={processor.COMMENT2}/>
                        <input type="hidden" name="CUSTID" value={processor.CUSTID}/>
                        <input type="hidden" name="ENROLLMENT_ID" value={processor.ENROLLMENT_ID}/>
                        <input type="hidden" name="EVENT_ID" value={processor.EVENT_ID}/>
                        <input type="hidden" name="EVENT_NAME" value={processor.EVENT_NAME}/>
                        <input type="hidden" name="EXT_TRANS_ID" value={processor.EXT_TRANS_ID}/>
                        <input type="hidden" name="EXT_TRANS_ID_LABEL" value={processor.EXT_TRANS_ID_LABEL}/>
                        <input type="hidden" name="ORIGIN" value={processor.ORIGIN}/>
                        <input type="hidden" name="PAY_TYPE" value={processor.PAY_TYPE}/>
                        <input type="hidden" name="PUBLIC_ENROLLMENT_ID" value={processor.PUBLIC_ENROLLMENT_ID}/>
                        <input type="hidden" name="VALIDATION_KEY" value={processor.VALIDATION_KEY}/>
                        <input type="hidden" name="SUCCESS_LINK" value={processor.SUCCESS_LINK}/>
                        <button type="submit">
                            {properties.registrationPayNow}
                        </button>
                    </form>
                }

                // if the processor is not upay, use paypal
                return <form id="paypal" method="POST" action="https://payflowlink.paypal.com" target="_top">
                    <input type="hidden" name="AMOUNT" value={processor.AMOUNT}/>
                    <input type="hidden" name="COMMENT2" value={processor.COMMENT2}/>
                    <input type="hidden" name="CUSTID" value={processor.CUSTID}/>
                    <input type="hidden" name="DESCRIPTION" value={processor.DESCRIPTION}/>
                    <input type="hidden" name="EMAIL" value={processor.EMAIL}/>
                    <input type="hidden" name="LOGIN" value={processor.LOGIN}/>
                    <input type="hidden" name="PARTNER" value={processor.PARTNER}/>
                    <input type="hidden" name="TYPE" value={processor.TYPE}/>
                    <input type="hidden" name="USER1" value={processor.USER1}/>
                    <input type="hidden" name="USER2" value={processor.USER2}/>
                    <input type="hidden" name="USER3" value={processor.USER3}/>
                    <input type="hidden" name="USER4" value={processor.USER4}/>
                    <input type="hidden" name="USER10" value={processor.USER10}/>
                    <button type="submit">
                        {properties.registrationPayNow}
                    </button> 
                </form>
            
            case 'none':
            case 'invoice':
                return <button onClick={() => window.location="https://som.yale.edu/"}>{properties.okButton}</button>
        }
    }

    const displayPayLaterButton = function() {
        switch (paymentType) {
            default:
            case null:
            case 'invoice':
            case 'both_cc_and_invoice':
                return <button onClick={props.onHide} className="ml-2">
                    {properties.registrationPayLater}
                </button>

            case 'none':
            case 'credit_card':
                return <></>
        }
    }

    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
            keyboard={false}
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.registrationNextStep}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Alert variant="primary">
                    <span dangerouslySetInnerHTML={{ __html: paymentDetails.replace('%payment_fee%', `$${processor.AMOUNT}`) }}/>
                </Alert>
                <ButtonToolbar style={{ justifyContent: 'flex-end' }}>
                    {displayPayNowButton()}
                    {displayPayLaterButton()}
                </ButtonToolbar>
            </Modal.Body>
        </Modal>
    )
}

const TermsAndConditionsModal = props => {
    let onAccept = props.onAccept
    let terms = props.terms || ''
    
    return (
        <Modal
            show={props.show}
            onHide={props.onHide}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            backdrop="static"
            centered
        >
            <Modal.Header closeButton>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.termsAndConditions}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: terms }}/>
            <Modal.Footer>
                <ButtonToolbar>
                    <button onClick={onAccept}>{properties.termsAndConditionsAccept}</button>
                    <button onClick={props.onHide} className="ml-2">{properties.termsAndConditionsDecline}</button>
                </ButtonToolbar>
            </Modal.Footer>
        </Modal>
    )
}

const PayLaterConfirmationModal = props => {
    return (
        <Modal
            show={props.show}
            size="lg"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            backdrop="static"
        >
            <Modal.Header>
                <Modal.Title id="contained-modal-title-vcenter">
                    {properties.registrationComplete}
                </Modal.Title>
            </Modal.Header>
            <Modal.Body dangerouslySetInnerHTML={{ __html: props.registrationComplete }}/>
            <Modal.Footer>
                <form action="https://som.yale.edu/programs/executive-education">
                    <button type="submit" className="ml-2">
                        {properties.okButton}
                    </button> 
                </form>
            </Modal.Footer>
        </Modal>
    )
}

export default EventForm